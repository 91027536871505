import React, { Fragment } from 'react';
import { FormControl, FormCheck, Row, Col, FormLabel } from "react-bootstrap";
import NumberFormat from "react-number-format";
import Input from '@material-ui/core/Input';
import CurrencyInput from './CurrencyInput';
import ModernDatepicker from 'react-modern-datepicker';
import moment from 'moment-timezone';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Lists from "./Lists";
import F from './Functions';
import MaskedInput from "react-text-mask";
import emailMask from 'text-mask-addons/dist/emailMask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import LinearProgress from "@material-ui/core/LinearProgress";

const masks = {
    trn: [/[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/],
    phoneNumber: [
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
    ],
};

export class DateText extends React.Component {
    render() {
        let value = this.props.value || this.props.date;
        let format = this.props.format || "MMM D, YYYY";
        if (this.props.showTime) {
            format = "MMM D, YYYY h:mm:ss A";
        }
        let dateValue = moment(value).format(format);
        if (!value) {
            dateValue = 'N/A';
        }

        return (
            <span>{dateValue}</span>
        )
    }
}

export class RelativeDate extends React.Component {
    render() {
        let dateValue = this.props.value || this.props.date;
        let timeElapsed = moment(new Date).diff(moment(dateValue), 'seconds');// + 5*60*60;
        let suffix = '';
        let relativeDate = moment(dateValue).format("MMMM D, YYYY LT");
        if (this.props.dateOnly) {
            relativeDate = moment(dateValue).format("LL");
        }

        if (timeElapsed < 0) {
            relativeDate = '-';
        } else if (timeElapsed < 60) {
            relativeDate = 'Moments ago';
        } else if (timeElapsed < 120) {
            relativeDate = 'A minute ago';
        } else if (timeElapsed < 3600) {
            relativeDate = Math.round(timeElapsed / 60);
            suffix = 'mins ago';
        } else if (timeElapsed < 3660) {
            relativeDate = 'an hour ago';
        } else if (timeElapsed < 86400) {
            relativeDate = (timeElapsed / 3600).toFixed(0);
            suffix = 'hrs ago';
        } else if (timeElapsed < 172800) {
            relativeDate = '';
            suffix = 'Yesterday';
        } else if (timeElapsed < 604800) {
            relativeDate = Math.round(timeElapsed / 86400);
            suffix = 'days ago';
        }

        if (this.props.titleCase) {
            suffix = (suffix || '').toTitleCase();
        } else {
            relativeDate = (relativeDate || '').toString().toLowerCase();
        }

        const dateText = !this.props.dateOnly ? (dateValue ? <>
            <span
                title={moment(dateValue).format("MMMM D, YYYY HH:mm:ss TZ")}>
                {relativeDate}{relativeDate && <>&nbsp;</>}{suffix}
            </span>
        </> : null) : moment(dateValue).format('LL');

        return (
            <>
                {dateText}
            </>
        )
    }
}

export class CurrencyText extends React.Component {
    render() {
        let currency = '$', value = this.props.value || 0;
        if (value < 0) {
            currency = '-$';
            value = value * -1;
        }
        let negativeClass = '';

        if (this.props.value < 0) {
            negativeClass = 'negative';
        }

        let suffix = '', decimalScale = 2, fixedDecimalScale = true;

        if (this.props.shorten) {
            if (Math.abs(value) >= 1000000000) {
                value /= 1000000000;
                suffix = 'b';
                decimalScale = 1;
                fixedDecimalScale = false;
            } else if (Math.abs(value) >= 1000000) {
                value /= 1000000;
                suffix = 'm';
                decimalScale = 1;
                fixedDecimalScale = false;
            } else if (Math.abs(value) >= 10000) {
                value /= 1000;
                suffix = 'k';
                decimalScale = 1;
                fixedDecimalScale = false;
            }

            // value = value.toFixed(1);
        }

        fixedDecimalScale = fixedDecimalScale && Math.round(value) !== value || this.props.fixedDecimalScale;

        return (
            <>
                {value > 0 &&
                    <span className={negativeClass + " text-right"}>
                        <NumberFormat
                            value={value}
                            decimalScale={decimalScale}
                            fixedDecimalScale={fixedDecimalScale}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={currency}
                            suffix={suffix}
                            autoComplete={"off"}
                            title={this.props.value}
                        />
                    </span>
                }
                {!value &&
                    <>
                        {this.props.default || '$0'}
                    </>}
            </>
        )
    }
}

export class PercentageText extends React.Component {
    render() {
        const result = this.props.value ? <>{this.props.value}%</> : 'N/A';
        return (
            <>{result}</>
        )
    }
}

export class DecimalText extends React.Component {
    render() {
        return (
            <span>{this.props.value}</span>
        )
    }
}

export class IntegerText extends React.Component {
    render() {
        return (
            <span>{this.props.value}</span>
        )
    }
}

export class CurrencyField extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };
    }

    render() {

        const label = this.props.label ? <label className={"form-label"}>{this.props.label}</label> : null;

        return (
            <>
                {label}
                <MoneyInputField
                    type="number"
                    className={"currency-field " + this.props.className}
                    fieldName={this.props.name}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    format={this.props.format}
                />
            </>
        )

    }

}

export class RadioField extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className="radio">
                <label>
                    <FormCheck
                        type={this.props.type || "radio"}
                        inline
                        name={this.props.name}
                        onChange={this.props.onChange}
                        checked={this.props.checked}
                    /> {this.props.label}
                </label>
            </div>
        )

    }

}

export class MoneyInputField extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };
    }

    render() {
        let type = this.props.type || "number";
        let label = this.props.label || "";
        let format = this.props.format || "currency";

        let prefix = "$ ";
        let suffix = '';
        let fixedDecimalScale = true;
        let placeholder = '$';
        let decimalScale = 2;

        if (format === 'percentage') {
            placeholder = '';
            suffix = '%';
            fixedDecimalScale = false;
            decimalScale = 8;
            prefix = '';
        }

        if (format === 'number') {
            placeholder = '';
            suffix = '';
            fixedDecimalScale = false;
            prefix = '';
        }

        const moneyField = <NumberFormat
            className={`form-control field-value money-input-field no-outline ${this.props.className}`}
            name={this.props.fieldName}
            onChange={this.props.onChange}
            value={this.props.value > 0 ? this.props.value : null}
            placeholder={placeholder}
            displayType={"input"}
            thousandSeparator={','}
            thousandsGroupStyle={"thousand"}
            isNumericString={true}
            type={"text"}
            decimalSeparator={'.'}
            prefix={prefix}
            suffix={suffix}
            autoComplete={"off"}
            fixedDecimalScale={fixedDecimalScale}
            decimalScale={decimalScale}
        />;

        let errorText = F.recursivePath(this.props.errors, this.props.fieldName);
        let helpText = this.props.helpText;

        return (
            <Fragment>
                {label && <label className={`form-label ${this.props.labelClassName}`}>{label}</label>}
                {moneyField}
                {errorText && <span className={"text-danger"}>{errorText}</span>}
                {helpText && <span className={"help-text help-block"}>{helpText}</span>}
            </Fragment>
        );

    }

}

export class DecimalInputField extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };
    }

    render() {
        let type = this.props.type || "number";
        let label = this.props.label || "";
        const inputField = <NumberFormat
            className={`form-control field-value money-input-field no-outline ${this.props.className}`}
            name={this.props.fieldName}
            onChange={this.props.onChange}
            value={this.props.value}
            displayType={"input"}
            thousandSeparator={','}
            thousandsGroupStyle={"thousand"}
            type={"text"}
            decimalSeparator={'.'}
            autoComplete={"off"}
        />;

        return (
            <>
                {inputField}
            </>
        )

        /*if ( label === '' ) {
            return (
                <Row className="currency-input-field-container">
                    <Col md={12} className="field-input-container">
                        {moneyField}
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className="currency-input-field-container">
                    <Col md={6} className="field-label">
                        {this.props.label}
                    </Col>
                    <Col md={6} className="field-input-container">
                        {moneyField}
                    </Col>
                </Row>
            );
        }*/

    }

}

export class GenericField extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };
    }

    changeDate(fn, field, value) {
        console.log(value, 'date before');
        value = moment(value).add(7, 'hours').tz('America/Jamaica').format('YYYY-MM-DD HH:mm:ss Z');
        console.log(value, 'date after');
        return fn(field, value);
    };

    render() {
        let type = this.props.type || "text";
        let label = !this.props.hideLabel ? <label className={"form-label"}>
            {this.props.label} {this.props.required && <span className={"text-danger"}>*</span>}
        </label> : null;
        let helpText = this.props.helpText ? <span className={"help-block help-text"}>{this.props.helpText}</span> : null;
        let errorText = F.recursivePath(this.props.errors, this.props.fieldName);
        if (this.props.format === 'currency' || this.props.format === 'percentage' || this.props.format === 'number') {

            let field =
                <CurrencyField
                    className={"field-value " + this.props.className}
                    format={this.props.format}
                    name={this.props.fieldName || this.props.name}
                    value={this.props.value || '0'}
                    onChange={(this.props.readOnly || this.props.disabled) ? () => {} : this.props.onChange}
                    autoComplete={"off"}
                    errors={this.props.errors}
                    helpText={this.props.helpText}
                    readOnly={this.props.readOnly}
                    disabled={this.props.disabled}
                />;

            return (
                <>
                    {label}
                    {field}
                    {helpText}
                    {errorText && <span className={"error-text"}>{errorText}</span>}
                </>
            )

        } else {

            let field = <FormControl
                type={type}
                className={"field-value " + this.props.className}
                name={this.props.fieldName || this.props.name}
                onChange={(this.props.readOnly || this.props.disabled) ? null : this.props.onChange}
                value={this.props.value}
                placeholder={this.props.placeholder}
                autoComplete={"off"}
                readOnly={this.props.readOnly}
                disabled={this.props.disabled}
            />;

            if (this.props.mask) {
                field = <>
                    <MaskedInput
                        mask={this.props.mask !== 'email' ? (this.props.mask !== 'decimal' ? masks[this.props.mask] : createNumberMask) : emailMask}
                        type={type}
                        className={"form-control field-value " + this.props.className}
                        name={this.props.fieldName || this.props.name}
                        onChange={(this.props.readOnly || this.props.disabled) ? null : this.props.onChange}
                        value={this.props.value}
                        autoComplete={"off"}
                        placeholder={this.props.placeholder}
                        readOnly={this.props.readOnly}
                        disabled={this.props.disabled}
                    />
                </>
            }

            if (this.props.type === 'textarea') {
                field = <FormControl as={"textarea"}
                    rows={this.props.rows || 3}
                    type={type}
                    className={"field-value " + this.props.className}
                    name={this.props.fieldName || this.props.name}
                    onChange={(this.props.readOnly || this.props.disabled) ? null : this.props.onChange}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    autoComplete={"off"}
                    readOnly={this.props.readOnly}
                    disabled={this.props.disabled}
                />;
            }

            if (this.props.type === 'dropdown') {
                field = <SelectField
                    className={"field-value " + this.props.className}
                    name={this.props.fieldName || this.props.name}
                    onChange={(this.props.readOnly || this.props.disabled) ? null : this.props.onChange}
                    value={this.props.value}
                    list={this.props.list}
                    label={this.props.label}
                    showLabel={this.props.showLabel || true}
                    required={this.props.required}
                    readOnly={this.props.readOnly}
                    disabled={this.props.disabled}
                />;
                label = null;
            }

            if (this.props.type === 'radio') {
                field = <div className={"push-down"}>
                    {Lists.getList(this.props.list).map((radio, key) => (
                        <span key={key}>
                            <RadioField
                                className={"field-value " + this.props.className}
                                name={this.props.fieldName || this.props.name}
                                onChange={(e) => (this.props.readOnly || this.props.disabled) ? null : this.props.onChangeRadio(e, this.props.name, radio.value)}
                                value={this.props.value}
                                checked={this.props.value === radio.value}
                                label={radio.label}
                                readOnly={this.props.readOnly}
                                disabled={this.props.disabled}
                            />
                        </span>
                    ))}
                </div>;
            }

            if (this.props.type === 'divider') {
                field = <hr />;
                label = null;
            }

            if (this.props.type === 'heading') {
                field = <h3>{this.props.content}</h3>;
                label = null;
            }

            if (this.props.type === 'label') {
                field = null;
                label = <label>{this.props.content}</label>;
            }

            if (this.props.type === 'checkbox') {
                field = <RadioField
                    type={"checkbox"}
                    className={"field-value " + this.props.className}
                    name={this.props.fieldName || this.props.name}
                    onChange={(this.props.readOnly || this.props.disabled) ? null : this.props.onChangeCheck}
                    checked={this.props.checked}
                    label={this.props.label}
                    readOnly={this.props.readOnly}
                    disabled={this.props.disabled}
                />;
                label = null;
            }

            return (
                <div className={"no-push-down " + this.props.containerClassName}>
                    {this.props.type !== 'date' &&
                        <>
                            {label}
                            {field}
                            {helpText}
                        </>
                    }
                    {this.props.type === 'date' &&
                        <>
                            {label}
                            <ModernDatepicker
                                name={this.props.fieldName || this.props.name}
                                className={"field-value " + this.props.className}
                                value={this.props.value}
                                date={this.props.value}
                                format={'YYYY-MM-DD'}
                                showBorder
                                onChange={(date) => (this.props.readOnly || this.props.disabled) ? null : this.changeDate(this.props.onChangeDate, this.props.name, date)}
                                placeholder={'Select a date'}
                                readOnly={this.props.readOnly}
                                disabled={this.props.disabled}
                            />
                            <a href={"#!"} onClick={(this.props.readOnly || this.props.disabled) ? null : (e) => { e.preventDefault(); this.props.onChangeDate(this.props.name, null) }} className={"pull-right"}>clear</a>
                        </>
                    }
                </div>
            )

        }

    }

}

export class SideMenuItem extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };
    }

    render() {

        const active = this.props.target && (this.props.pageView || {}).currentView === this.props.target ? ' selectedMenu' : '';

        return (
            <>
                <li aria-haspopup="true" data-ktmenu-submenu-toggle="hover"
                    className={"kt-menu__item kt-menu__item--here aside-menu " + active}
                    data-placement="right">
                    <a href="#pageTop" className="kt-menu__link kt-menu__toggle"
                        onClick={this.props.onClick}>
                        <i className={"kt-menu__link-icon " + this.props.icon}></i>
                        <span className="kt-menu__link-text" style={{ "whiteSpace": "nowrap" }}>
                            {this.props.label}
                        </span>
                    </a>
                </li>
            </>
        )

    }

}

export class OriginationSummaryItem extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };
    }

    render() {

        return (
            <div className="origination-summary-item">
                <h4 className="origination-summary-item-title">{this.props.label}</h4>
                {this.props.text &&
                    <span className="origination-summary-item-text">
                        {this.props.text}
                    </span>
                }
                {this.props.value &&
                    <span className="origination-summary-item-text">
                        <CurrencyText
                            value={this.props.value}
                        />
                    </span>
                }
            </div>
        )

    }

}

export class MoneyInfoField extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };
    }

    render() {

        let detailButton = '';
        if (this.props.detailButtonOnClick) {
            detailButton = <button
                className="btn btn-sm btn-secondary btn-circle"
                onClick={this.props.detailButtonOnClick}>
                <i className="fa fa-ellipsis-h"></i>
            </button>;
        }

        return (
            <Row className={"currency-info-field-container " + this.props.className}>
                <Col md={6} className="field-label">
                    {this.props.label} {detailButton}
                </Col>
                <Col md={6} className="field-value">
                    <CurrencyText
                        value={this.props.value}
                    />
                </Col>
            </Row>
        )

    }

}

export class DecimalInfoField extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };
    }

    render() {

        return (
            <Row className={"currency-info-field-container " + this.props.className}>
                <Col md={6} className="field-label">
                    {this.props.label}
                </Col>
                <Col md={6} className="field-value">
                    <DecimalText
                        value={this.props.value}
                    />
                </Col>
            </Row>
        )

    }

}

export class TextField extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <span>
                <FormControl
                    type="text"
                    className="text-field"
                    name={this.props.name}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    autoComplete={"off"}
                />
            </span>
        )

    }

}

export class Pluralise extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };
    }

    render() {
        let description = this.props.value === 1 ? this.props.singular : this.props.plural;
        return (
            <>
                {!this.props.descriptionOnly && <>{this.props.value}&nbsp;</>}{description}
            </>
        )

    }

}

export class SelectField extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            value: props.value
        };

    }

    static getDerivedStateFromProps(newProps, oldState) {
        let newState = { value: newProps.value || null };
        return oldState.value === newProps.value ? null : newState;
    }

    componentDidMount() {

    }

    render() {

        let optionsList = this.props.list || [];

        if (typeof this.props.list === 'string') {

            optionsList = Lists.getList(this.props.list);

        } else {

            if (Array.isArray(optionsList[0])) {
                let options = [];
                for (let o = 0; o < optionsList.length; o++) {
                    options.push({ label: optionsList[o][0], value: optionsList[o][1] });
                }
                optionsList = options;
            }

        }

        return (
            <span>
                {(this.props.showLabel || !this.props.hideLabel) && <label className={"form-label"}>{this.props.label} {this.props.required && <span className={"text-danger"}>*</span>}</label>}
                <Select
                    value={this.props.value || (this.state.value || "")}
                    onChange={this.props.onChange}
                    name={this.props.name}
                    displayEmpty
                    className={"mt-3 w-100 " + this.props.className + " bootstrap-type-select"}
                    disableUnderline={this.props.disableUnderline || true}
                >
                    <MenuItem value="">
                        <span className={"text-danger"}>
                            {this.props.placeholder &&
                                <>{this.props.placeholder}</>
                            }
                            {!this.props.placeholder &&
                                <>&nbsp;
                                </>
                            }
                        </span>
                    </MenuItem>
                    {(optionsList || []).map((item, key) => (
                        <MenuItem value={item.value} key={key} disabled={item.disabled}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            </span>
        )

    }

}

export class StaticProgress extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };
    }

    render() {
        let value = this.props.value;
        let statusColour = 'primary';
        let colourClass = '';
        if (value < 30) {
            statusColour = "secondary";
        } else if (value === 100) {
            colourClass = 'progressSuccess';
        }
        const markUp = <div className={"progress-column"}>
            <LinearProgress value={value || 0} color={statusColour} variant="determinate" className={colourClass} />
        </div>;
        return (
            <>
                {markUp}
            </>
        )

    }

}

export class TextInputField extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };
    }

    onChange = event => { };

    render() {
        let type = this.props.type || "text";

        if (this.props.format === 'currency') {

            return (
                <MoneyInputField
                    fieldName={this.props.fieldName}
                    value={this.props.value}
                    label={this.props.label}
                    onChange={this.props.onChange}
                />
            )

        } else {

            let classes = "w-100 mb-3 push-down-input" + this.props.className;

            if (this.props.readOnly) {
                classes += " no-border text-grey";
            }

            if (type !== 'number') {
                return (
                    <>
                        <span className="field-input-container text-left">
                            <Input
                                type={this.props.type || 'text'}
                                className={classes}
                                name={this.props.fieldName || this.props.name}
                                onChange={this.props.onChange}
                                value={this.props.value}
                                autoComplete={"off"}
                                readOnly={this.props.readOnly}
                                disableUnderline={this.props.readOnly}
                                multiline={type === 'textarea'}
                                min={0}
                                onKeyPress={this.props.onKeyPress}
                                placeholder={this.props.placeholder}
                            />
                        </span>
                    </>
                )
            } else {
                return (
                    <>
                        <span className="field-input-container text-left">
                            <Input
                                type={this.props.type || 'text'}
                                className={classes}
                                name={this.props.fieldName || this.props.name}
                                onChange={this.props.onChange}
                                value={this.props.value}
                                autoComplete={"off"}
                                readOnly={this.props.readOnly}
                                disableUnderline={this.props.readOnly}
                                multiline={type === 'textarea'}
                                max={this.props.max || undefined}
                                min={this.props.min || undefined}
                            />
                        </span>
                    </>
                )
            }

        }

    }

}
